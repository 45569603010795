import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const Menu = ({onVisionClick, onServicesClick, onMissionClick, onAboutClick, onContactClick}) => {
  
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/">
            <img src={require('../images/logo.png')} className='menuLogo' alt='Zentralle Sammlerstelle Logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">ZENTRALE SAMMLERSTELLE</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#" onClick={onVisionClick}>Vision</Nav.Link>
            <Nav.Link href="#" onClick={onServicesClick}>Dienstleistungen</Nav.Link>
            <Nav.Link href="#" onClick={onMissionClick}>Mission</Nav.Link>
            <Nav.Link href="#" onClick={onAboutClick}>Über uns</Nav.Link>
            <Nav.Link href="#" onClick={onContactClick}>Kontakt</Nav.Link>

            <div className='d-flex flex-row gap-3'>
              <button className='button' onClick={() => window.location.href = '/showroom'}>
                  Showroom
              </button>

              <button className='button' onClick={() => window.location.href = '/anmelden'}>
                  Login
              </button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Menu