// App.js
import { Routes, Route } from 'react-router-dom';
import React from 'react';

import Home from './pages/Home';
import Login from './pages/Login';
import AGB from './pages/AGB';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import FAQ from './pages/FAQ';
import KundenPortal from './pages/KundenPortal';
import Showroom from './pages/Showroom';


// import the PrivateRoute
import PrivateRoute from './PrivateRoute';


const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/anmelden" element={<Login />} />
      <Route path='/agb' element={<AGB />} />
      <Route path='/impressum' element={<Impressum />} />
      <Route path='/datenschutz' element={<Datenschutz />} />
      <Route path='/faq' element={<FAQ />} />
      <Route path='showroom' element={<Showroom />} />
      <Route path='/kunden_portal' element={<PrivateRoute/>}>
         <Route
            path="/kunden_portal"
            element={<KundenPortal />}
         />
      </Route>
      
    </Routes>
  );
};

export default App;
