import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/portalMenu.module.css';

const PortalMenu = ({ setActiveTab, showMobileMenu, setShowMobileMenu, userName}) => {

    const [buttons, setButtons] = useState([
        {
            id: 0,
            title: 'Nachrichten',
            icon: require('../images/icons/mail.png'),
            active: true,
            value: 0,
        },
        {
            id: 1,
            title: 'Ihre Artikel',
            icon: require('../images/icons/ancient.png'),
            active: false,
            value: 1,
        },
        {
            id: 2,
            title: 'Mein Profil',
            icon: require('../images/icons/user.png'),
            active: false,
            value: 2,
        },
        {
            id: 3,
            title: 'Kontakt',
            icon: require('../images/icons/support.png'),
            active: false,
            value: 3,
        },
    ]);

    const selectTab = (id, newValue) => {
        // Create a new array with updated active values
        const updatedButtons = buttons.map((element) => ({
            ...element,
            active: element.id === id,
        }));
    
        // Update the state with the new array
        setButtons(updatedButtons);
        setActiveTab(newValue);
        setShowMobileMenu(false);
    };

    const logUserOut = () => {
        //we'll have to remove the status from DB too
        localStorage.removeItem('sammlerToken');
        sessionStorage.removeItem('sammlerToken');

        window.location.href = "/anmelden";
    }
 
  return (
    <Container fluid className={showMobileMenu ? styles.mobileMain : styles.main}>
        <div>
            <Row className='border-bottom mb-3'>
                {showMobileMenu &&
                    <Col className='col-12 p-3 text-end'>
                       <img src={require('../images/icons/close.png')} alt='Close Menu' className='iconS' onClick={() => setShowMobileMenu(false)}/>
                    </Col>
                }
                <Col className='p-0 text-center'>
                    <img className={styles.logo} src={require('../images/logo_text_noBKG.png')} alt='Zentrale Sammlerstell Logo'/>
                </Col>
            </Row>

            <Row>
                <Col className='d-flex gap-2'>
                    <img src={require('../images/icons/user.png')} className='iconS' alt='User Sammler'/>
                    <p>{userName}</p>
                </Col>
            </Row>

            {buttons.map((item) => {
                return (
                    <Row className='mb-2' key={item.id}>
                        <Col>
                            <div onClick={() => selectTab(item.id, item.value)} className={item.active ? styles.buttonActive : styles.buttonNotActive}>
                                <img src={item.icon} alt="Button Icon" className='iconS'/>
                                <span>{item.title}</span>
                            </div>
                        </Col>
                    </Row>
                )
            })}
        </div>

        <Row>
            <Col className='col-12 d-flex flex-column gap-1 text-center mb-2'>
                <a className={styles.links} href='/agb'>AGB</a>
                <a className={styles.links} href='/impressum'>Impressum</a>
                <a className={styles.links} href='/datenschutz'>Datenschutz</a>
            </Col>
            <Col className='col-12 d-flex flex-column gap-1 text-center'>
                <div className={styles.buttonNotActive} onClick={logUserOut}>
                    <img src={require('../images/icons/logout.png')} alt="Button Icon" className='iconS'/>
                    <span>Abmelden</span>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default PortalMenu