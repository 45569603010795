import React from "react";
import Menu from '../components/Menu';
import Footer from  '../components/Footer';
import Container from 'react-bootstrap/Container';
import Faq from 'react-faq-component';

const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "Kann ich selbst neue Benutzer hinzufügen?",
        content: "Nein, nur der Administrator hat die Berechtigung, neue Benutzer hinzuzufügen. Wenn Sie Unterstützung benötigen, wenden Sie sich bitte an den Administrator."
      },
      {
        title: "Wie kann ich neue Artikel zur Plattform hinzufügen?",
        content: "Nur der Administrator kann neue Artikel zur Plattform hinzufügen. Falls Sie ein neues Element hinzufügen möchten, setzen Sie sich bitte mit dem Administrator in Verbindung."
      },
      {
        title: "Kann ich den Administrator kontaktieren, um Informationen hinzuzufügen oder zu ändern?",
        content: "Ja, Sie können sich an den Administrator wenden, um Informationen zu Ihren Artikeln zu ändern oder hinzuzufügen."
      },
      {
        title: "Gibt es eine Möglichkeit, dass normale Benutzer Artikel hinzufügen können?",
        content: "Nein, nur der Administrator hat die Berechtigung, Artikel zur Plattform hinzuzufügen. Wenn Sie Artikel hinzufügen möchten, setzen Sie sich bitte mit dem Administrator in Verbindung."
      },
      {
        title: "Wie kann ich Kontakt mit dem Administrator aufnehmen?",
        content: "Sie können den Administrator über der Website erreichen, oder eine über info@zentralesammlerstelle.de."
      },
      {
        title: "Kann der Administrator meine Artikelinformationen bearbeiten?",
        content: "Ja, der Administrator kann Artikelinformationen bearbeiten. Wenn Sie Änderungen vornehmen müssen, wenden Sie sich bitte an den Administrator."
      },
      {
        title: "Kann ich den Status meines Artikels ändern?",
        content: "Nein, nur der Administrator kann den Status von Artikeln ändern. Wenn Sie Änderungen vornehmen müssen, setzen Sie sich bitte mit dem Administrator in Verbindung."
      },
      {
        title: "Wie erfahre ich, ob meine Artikelinformationen erfolgreich hinzugefügt wurden?",
        content: "Sie erhalten eine Benachrichtigung vom Administrator, sobald Ihre Artikelinformationen erfolgreich hinzugefügt wurden."
      },
    ]
}


const FAQ = () => {
  return (
    <div>
        <Menu />

        <Container className="p-5">
            <Faq data={data}/>
        </Container>

        <Footer />
    </div>
  )
}

export default FAQ