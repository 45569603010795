import { useState, useEffect } from "react";

const useUser = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('sammlerToken') || sessionStorage.getItem('sammlerToken');

        const fetchData = async () => {
            const userData = await fetchUser(token);
            const messagesData = await fetchMessages(token);
            const itemsData = await fetchItems(token);

            setUser({
                ...userData,
                messages: messagesData,
                items: itemsData,
            });
        };

        fetchData();
    }, []);

    const fetchUser = async (token) => {
        if(token) {
            try {
                const response = await fetch('https://zentralesammlerstelle.de/api/get_user.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if(response.ok) {
                    const data = await response.json();

                    if(data.success) {
                        return data.user;
                    } else {
                        return null;
                        console.log(data.message);
                    }
                    
                }else {
                    console.log('Error fetching user:', response.status, response.statusText)
                }
            } catch (error) {
                console.log('Error fetching user: ', error.message);
            }
        } else {
            console.log('Token not found')
        }
    };
    const fetchMessages = async (token) => {
        if(token) {
            try {
                const response = await fetch('https://zentralesammlerstelle.de/api/get_messages.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if(response.ok) {
                    const data = await response.json();

                    if(data.success) {
                        //add the list of messages to the user object as property "messages"
                        return data.messages;
                    } else {
                        console.log(data.message);
                        return null;
                    }
                    
                }else {
                    console.log('Error fetching messages:', response.status, response.statusText)
                }
            } catch (error) {
                console.log('Error fetching messages: ', error.message);
            }
        } else {
            console.log('Token not found')
        }
    };
    const fetchItems = async (token) => {
        if(token) {
            try {
                const response = await fetch('https://zentralesammlerstelle.de/api/get_items.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if(response.ok) {
                    const data = await response.json();

                    if(data.success) {
                        //add the list of items to the user object as property "messages"
                        return data.items;
                    } else {
                        return null;
                        console.log(data.message);
                    }
                    
                }else {
                    console.log('Error fetching items:', response.status, response.statusText)
                }
            } catch (error) {
                console.log('Error fetching items: ', error.message);
            }
        } else {
            console.log('Token not found')
        }
    };

    return user;
};

export default useUser;