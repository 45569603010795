import React, { useState } from 'react';
import styles from '../styles/showroom.module.css';
import ShopContactForm from './ShopContactForm';

const ShowroomItem = ({item}) => {
    const imageBaseURL = "https://zentralesammlerstelle.de/api/items_images/";
    const [showFormular, setshowFormular] = useState(false);

  return (
    <div className={styles.showroomItemCont} style={{backgroundImage: `url(${imageBaseURL + item.item_images})`}}>
        {showFormular &&
            <ShopContactForm selectedItem={item} />
        }

        <div className={styles.itemInfoCont}>
            <span className={styles.itemTitle}>{item.item_title}</span>

            <div className='opacity-75 d-flex justify-content-between w-100'>
                <span>Verkaufspreis:</span>
                <span className={styles.itemPrice}>{item.item_desired_price}€</span>
            </div>

            <button className={styles.buyBtn} onClick={() => setshowFormular(true)}>
                Anfrage senden
            </button>
        </div>
    </div>
  )
}

export default ShowroomItem