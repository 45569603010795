import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const isAuthenticated = localStorage.getItem('sammlerToken') || sessionStorage.getItem('sammlerToken');

  

  return isAuthenticated ? <Outlet /> : <Navigate to="/anmelden" />;
};

export default PrivateRoute;
