import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import styles from '../styles/portalForm.module.css';
import useUser from '../hooks/useUser';

const PortalForm = ({userEmail}) => {
    const [subject, setSubject] = useState(null);
    const [description, setDescription] = useState(null);
    const user = useUser();

    
    const sendForm = async () => {
        const formData = new FormData();
        formData.append('email', user.user_email);
        formData.append('vorname', user.user_vorname);
        formData.append('nachname', user.user_nachname);
        formData.append('message', description);
        formData.append('subject', subject)

        try {
            const response = await fetch('http://zentralesammlerstelle.de/api/contact_email.php', {
                method: 'POST',
                body: formData
            })

            if(response.ok) {
                const data = await response.json();

                if(data.success) {
                    window.location.reload(false);
                } else {
                    alert(data.message);
                }
            } else {
                console.log('Error sending the email');
            }
        } catch (error) {
            console.log('Error sending the E-amail:', error.message);
        }

    }

  return (
    <Row className='h-100 d-flex align-items-center justify-content-center px-md-5'>
        <div className={styles.formCont}>
            <div className='d-flex flex-column gap-2 mb-2'>
                <label>Betreff</label>
                <select name="cars" id="cars" onChange={(e) => setSubject(e.target.value)}>
                    <option value="volvo">Probleme mit Passwort</option>
                    <option value="saab">Anmeldeprobleme</option>
                    <option value="opel">Probleme mit Datei-Uploads</option>
                    <option value="audi">Seitenladeprobleme </option>
                    <option value="volvo">Probleme mit dem Formular</option>
                    <option value="saab">Falsche Benutzerdaten</option>
                    <option value="opel">Artikel wird nicht angezeigt</option>
                    <option value="audi">Andere...</option>
                </select>
            </div>
            <div className='d-flex flex-column gap-2 mb-2'>
                <label htmlFor='w3review'>Beschreibung</label>
                <textarea name="w3review" rows="4" onChange={(e) => setDescription(e.target.value)}/>
            </div>
            
            <button onClick={sendForm} className={styles.sendEmailBtn}>SENDEN</button>
        </div>
    </Row>
  )
}

export default PortalForm