import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ShowroomItem from './ShowroomItem';
import styles from '../styles/showroom.module.css';

const RenderShowroom = ({ items }) => {
  // Determine the number of columns based on the desired layout
  // For example, assuming a 3-column layout for simplicity
  const columnsPerRow = 3;
  
  // Calculate the number of rows needed
  const numberOfRows = Math.ceil(items.length / columnsPerRow);

  return (
    <Container>
      {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
        <Row key={rowIndex} className='mb-4' style={{ height: 300 }}>
          {items
            .slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow)
            .map((item, itemIndex) => (
              <Col key={itemIndex} className={`col-12 col-md-${12 / columnsPerRow} ${styles.itemCont}`}>
                <ShowroomItem item={item} />
              </Col>
            ))}
        </Row>
      ))}
    </Container>
  );
};

export default RenderShowroom;
