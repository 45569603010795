import React, {useState} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from '../styles/portal.module.css';

import PortalMenu from '../components/PortalMenu';
import useUser from '../hooks/useUser';
import UserCard from '../components/UserCard';
import PortalForm from '../components/PortalForm';


const KundenPortal = () => {
    const [activeTab, setActiveTab] = useState(0); 
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const user = useUser();
    const imageBaseURL = "https://zentralesammlerstelle.de/api/items_images/";

  return (
    <Container fluid className={styles.main}>
        {user ?
            <Row className='h-100'>

                <Col className='col-12 d-md-none p-2 d-flex justify-content-between'>
                    <img alt='Menu Icon' className='iconM' src={require('../images/icons/menu.png')} onClick={() => setShowMobileMenu(true)}/>
                    <img className={styles.logoSmall} src={require('../images/logo_text_noBKG.png')} alt='Zentrale Sammlerstell Logo'/>
                </Col>
                
                <Col className='col-md-3 d-none d-md-flex p-0'>
                    <PortalMenu userName = {user.user_name} setActiveTab={setActiveTab} setShowMobileMenu={setShowMobileMenu}/>
                </Col>

                {showMobileMenu &&
                    <PortalMenu  userName = {user.user_name} setShowMobileMenu={setShowMobileMenu} setActiveTab={setActiveTab} showMobileMenu={showMobileMenu}/>
                }

                <Col className='col-12 col-md-9' style={{height: '100vh', overflowY: 'scroll'}}>
                    {activeTab === 0 &&
                        <Container className='d-flex flex-column justify-content-between h-100'>
                            <div>
                                <Row className='my-2'>
                                    <Col className='col-12 my-2'>
                                        <h2 className='text-center'>Nachrichten</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    {user.messages.map((item) => {
                                        return(
                                            <Col key={item.message_id} className={`col-12 mb-3 ${styles.messageBody}`}>
                                                <h6>{item.messages_subject}</h6>
                                                <p className='textS'>{item.messages_content}</p>
                                                <div className='d-flex justify-content-between'>
                                                    <span className='textS textBold'>Absender: {item.message_sender}</span>
                                                    <span className='textS textBold'>{item.message_date}</span>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                            
                            <p className='text-center textS'>Wenn Sie mit uns Kontakt aufnehmen möchten, nutzen Sie das Kontaktformular im Menü.</p>
                        </Container>
                    }
                    {activeTab === 1 &&
                        <Container>
                            <Row className='my-2'>
                                <Col className='col-12 my-2'>
                                    <h2 className='text-center'>Ihre Artikel</h2>
                                </Col>
                            </Row>

                            {user.items ? 
                                (user.items.map((item) => {
                                    return(
                                        <Row key={item.item_id} className={`mb-3 ${styles.itemCont}`}>
                                            <Col className='col-12 col-xl-4'>
                                                <img className={styles.itemImage} src={imageBaseURL +  item.item_images} alt='antique item'/>
                                            </Col>
                                            <Col className='col-12 col-xl-8'>
                                                <h6 className="textBold">{item.item_title}</h6>
                                                <p className="textS opacity-75">{item.item_description}</p>
                                                <Row>
                                                    <Col className='col-12 col-lg-4 d-flex flex-column'>
                                                        <p>Geschätzte Preis: <strong>{item.item_estimated_price}€</strong></p>
                                                        <p>Gewünschter Preis: <strong>{item.item_desired_price}€</strong></p>
                                                    </Col>
                                                    <Col className='col-12 col-lg-4 text-center'>
                                                        <p>Status: {item.item_status}</p>
                                                        {item.item_sold === 1 &&
                                                            <div className={styles.soldPriceCont}>
                                                                <p className={styles.itemPrice}>Verkauft für: <strong>{item.item_sold_price}€</strong></p>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col className='col-12 col-lg-4 d-flex flex-row flex-md-column align-items-lg-end justify-content-between justify-content-xl-start'>
                                                        <p className='text-right m-0 mb-xl-3'>Auftrag Nummer: <strong>{item.item_code}</strong></p>

                                                        <div className='d-flex gap-1 align-items-center opacity-75'>
                                                            <img src={require('../images/icons/clock.png')} className='iconXS' alt='Clock Icon'/>
                                                            <p className='m-0'>{item.item_date}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                }))
                            :
                                <div className='pt-5'>
                                    <h5 className='text-center'>Anscheinend haben Sie noch keine Artikel.</h5>
                                    <img style={{ width: '100%', height: '100vh', objectFit: 'cover' }} alt='No Data' src={require('../images/No data.png')}/>
                                </div>
                                
                            }      
                            
                        </Container>
                    }
                    {activeTab === 2 &&
                        <Container>
                            <Row className='h-100'>
                                <Col className='p-0 p-md-5'>
                                    <UserCard 
                                        userName = {user.user_name}
                                        userImage = {user.user_image}
                                        clientNr = {user.user_client_nr}
                                        userVorname = {user.user_vorname}
                                        userNachname = {user.user_nachname}
                                        email = {user.user_email}
                                        phone = {user.user_mobile}
                                        country = {user.user_land}
                                        city = {user.user_city}
                                        street = {user.user_adres}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    }
                    {activeTab === 3 &&
                        <Container className='h-100'>
                            <PortalForm />
                        </Container>
                    }
                </Col>
            </Row>
        :
            <h5 className='text-center p-5'>Loading...</h5>
        }
    </Container>
  )
}

export default KundenPortal