import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/login.module.css';


const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState(null);

  const [resetCode, setResetCode] = useState(null);
  const [email, setEmail] = useState(null);

  const [wantReset, setWantReset] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [codeOK, setCodeOK] = useState(false);

  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    checkLocalStorage();
  }, [])

  const logUserIn = (e) => {
    e.preventDefault();

    //get the date and format it
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD

    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('password', password);
    formData.append('date', formattedDate);

    if(userName.length <=  0 || password.length <= 0) {
      showErrorMessage('Bitte füllen Sie alle Felder aus.', 3000)
    } else {
      fetch('https://zentralesammlerstelle.de/api/log_user_in.php', {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          //if the user select to stay loged in
          if(stayLoggedIn) {
            //set that the user want to stay loged in
            window.localStorage.setItem('sammlerStayON', true);

            //set the token to the localStorage so that token stay saved
            window.localStorage.setItem('sammlerToken', data.token);

            //redirect the user to the portal
            window.location.href = "/kunden_portal";
          }else {
            //store the token to localStorage
            window.sessionStorage.setItem('sammlerToken', data.token);

            //redirect the user to the user to portal
            window.location.href = "/kunden_portal";
          }

        } else {
          showErrorMessage(data.message, 3000)
        }
      })
      .catch(error => console.log("Error Loggin in: " + error.message))
    }
  }

  const handleNextBtn = (e) => {
    e.preventDefault();
    //LOGIC: 
    //1. First check if email id not null and code sent has to be false to send the Email with the code (the code will be saved in the DB with the user)
    //2. If the code is send successfuly we set codeSent to true, and the code field will show up
    //3. Secod click will run if the code Send is true, code is not null, and email is not null
    //4. The code and Email will be sent again to Backend and checked if the code is cored with the one in DB
    //5. If the response come positive we set the CodeOK to true and the field for new password will show up
    //6. Third click will send again the email, code, and new password to the backend, where will be checked
    //7. New password is hased and stored in DB

    if(email !== null && codeSent === false && codeOK === false) {
      //this make sure this part of code runs only if code and password are hidden, and don't run if the user allready have a code
      sendResetCode();
    }

    if(email !== null && resetCode !== null && codeSent && codeOK === false) {
      //this make sure this code block runs only if the user has typed the email and also the code
      //codeSent has to be true, witch means the code Email was successfuly sent
      //and codeOK is false
      checkResetCode();
    }

    if(email !== null && resetCode !== null && newPassword !== null && codeSent && codeOK) {
      resetUserPassword();
    }
  }

  const sendResetCode = async () => {
    const formData = new FormData();
    formData.append('email' , email);
  
    try {
      const response = await fetch('https://zentralesammlerstelle.de/api/send_reset_code.php',{
        method: 'POST',
        body: formData
      })

      if(response.ok) {
        const data = await response.json();

        if(data.success) {
          setCodeSent(true);
        }
        else {
          setCodeSent(false);
          showErrorMessage(data.message, 3000)
          console.log(response.statusText)
        }
      }

    }catch (error) {
      console.log("Error sending Reset Code: ", error.message);
    }
  }
  const checkResetCode = async() => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('resetCode', resetCode);

    try {
      const response = await fetch('https://zentralesammlerstelle.de/api/check_reset_code.php', {
        method: 'POST',
        body: formData
      })
      
      if(response.ok) {
        const data = await response.json();

        if(data.success) {
          setCodeOK(true);
        } else {
          setCodeOK(false);
          showErrorMessage(data.message, 3000)
        }
      }else {
        console.log('Error checking the Code: ', response.status);
      }
    }catch (error) {
      console.log(error);
    }

  }
  const resetUserPassword = async() => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('code', resetCode);
    formData.append('newPassword', newPassword);

    try {
      const response = await fetch('https://zentralesammlerstelle.de/api/reset_user_pass.php', {
        method: 'POST',
        body: formData
      }) 

      if(response.ok) {
        const  data = await response.json();

        if(data.success) {
          //password was successfully changed
          window.location.href = '/anmelden';
        }else {
          showErrorMessage(data.message, 3000);
        }
      }else {
        console.log('Error changing the Password: ', response.status);
      }
    }catch(error) {
      console.log(error);
    }
  }

  const showErrorMessage = (message, duration) => {
    //set the message
    setErrorMessage(message);
    setShowError(true)

    setTimeout(() => {
      setErrorMessage(null);
      setShowError(false)
    }, duration);
  }

  const checkLocalStorage = () => {
    if(localStorage.getItem('sammlerToken')) {
      window.location.href = "/kunden_portal";
    }
  }
  return (
    <Container fluid className={styles.main}>
      <Row className='h-100'>
        <Col className={`d-none col-md-8 col-lg-9 d-none d-md-flex ${styles.decoImage}`}>

        </Col>
        {wantReset ?
          <Col className='col-12 col-md-4 col-lg-3 d-flex flex-column justify-content-between' style={{backgroundColor: '#F5F5F5'}}>
            <div className={`d-flex gap-1 m-2 align-items-center ${styles.backBTN}`} onClick={() => setWantReset(false)}>
              <img src={require('../images/icons/back.png')} className='iconS' alt='Back Logo'/>
              <span className={styles.backBtnText}>ZURÜCK</span>
            </div>
            
            <div>
              <div className='d-flex gap-3 align-items-center justify-content-center mb-5'>
                <img src={require('../images/logo_black.png')} className='menuLogo' alt='Zentrale Sammlerstelle Logo'/>
                <h6 className='m-0'>ZENTRALE SAMMLERSTELLE</h6>
              </div>
              <h5 className={styles.pageTitle}>Passwort zurücksetzen</h5>
              <p className='text-center'>Geben Sie Ihre E-Mail Adresse ein</p>
            </div>

            <form className={styles.loginForm}>
              <div className='d-flex flex-column gap-1 mb-2'>
                <label className={styles.inputLabel}>E-Mail Adresse</label>
                <input className={styles.input} type='text' onChange={(e) => setEmail(e.target.value)}/>
              </div>
              {codeSent &&
                <div className='d-flex flex-column gap-1 mb-2'>
                  <label className={styles.inputLabel}>Code</label>
                  <input className={styles.input} type='text' onChange={(e) => setResetCode(e.target.value)}/>
                </div>
              }
              {codeOK &&
                <div className='d-flex flex-column gap-1 mb-2'>
                  <label className={styles.inputLabel}>Neues Passwort</label>
                  <input className={styles.input} type='password' onChange={(e) => setNewPassword(e.target.value)}/>
                </div>
              }

              <button className={styles.loginBtn} onClick={(e) => handleNextBtn(e)} >NEXT</button>
            </form>

            <div className={showError ? styles.errorBKG : styles.errorCont}>
              {showError &&
                <p className={styles.errorText}>{errorMessage}</p>
              }
            </div>

            <div>
              <p className={styles.noUserText}>Sie haben kein Konto? <a href='/'>Jetzt Kontakt aufnehmen!</a></p>
            </div>
          </Col>
        :
          <Col className='col-12 col-md-4 col-lg-3 d-flex flex-column justify-content-between' style={{backgroundColor: '#F5F5F5'}}>
            <div className={`d-flex gap-1 m-2 align-items-center ${styles.backBTN}`} onClick={() => window.location.href = '/'}>
              <img src={require('../images/icons/back.png')} className='iconS' alt='Back Logo'/>
              <span className={styles.backBtnText}>ZURÜCK</span>
            </div>
            
            <div>
              <div className='d-flex gap-3 align-items-center justify-content-center mb-5'>
                <img src={require('../images/logo_black.png')} className='menuLogo' alt='Zentrale Sammlerstelle Logo'/>
                <h6 className='m-0'>ZENTRALE SAMMLERSTELLE</h6>
              </div>
              <h5 className={styles.pageTitle}>LOGIN</h5>
              <p className='text-center'>Willkommen Zurück!</p>
            </div>

            <form className={styles.loginForm}>
              <div className='d-flex flex-column gap-1 mb-2'>
                <label className={styles.inputLabel}>Benutzername</label>
                <input className={styles.input} type='Text' onChange={(e) => setUserName(e.target.value)}/>
              </div>

              <div className='d-flex flex-column gap-1 mb-2'>
                <label className={styles.inputLabel}>Passwort</label>
                <input className={styles.input} type='password' onChange={(e) => setPassword(e.target.value)}/>
              </div>

              <div className='d-flex flex-row gap-1 mb-3'>
                <input type='checkbox' value={stayLoggedIn} onChange={(e) => setStayLoggedIn(e.target.value)}/>
                <span className={styles.inputLabel}>Bleibe eingeloggt</span>
              </div>

              <button className={styles.loginBtn} onClick={logUserIn} >LOGIN</button>

              <div className='text-center mt-3'>
                <p className={styles.resetPassword} onClick={() => setWantReset(true)}>Ich habe mein Passwort vergessen</p>
              </div>
            </form>

            <div className={showError ? styles.errorBKG : styles.errorCont}>
              {showError &&
                <p className={styles.errorText}>{errorMessage}</p>
              }
            </div>

            <div>
              <p className={styles.noUserText}>Sie haben kein Konto? <a href='/'>Jetzt Kontakt aufnehmen!</a></p>
            </div>
          </Col>      
        }
      </Row>
    </Container>
  )
}

export default Login