import React, {useState} from 'react';
import styles from '../styles/userCard.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import ImageSelector from './ImageSelector';


const UserCard = ({userName, userImage, clientNr, userVorname, userNachname, email, phone, country, city, street}) => {
    const prifileImageURL = "https://zentralesammlerstelle.de/api/user_images/";
    const [imageSelector, setImageSelector] = useState(false);
  
  return (
    <Container className={styles.main}>
        {imageSelector &&
            <ImageSelector setImageSelector={setImageSelector}/>
        }
        

        <Row className='gap-2 p-2'>
            <Col className='col-12 text-center d-flex align-items-end justify-content-center'>
                <img className={styles.profileImage} src={prifileImageURL + userImage} alt='ProfileImage' onClick={() => setImageSelector(true)}/>
                <img style={{ cursor: 'pointer' }} className='iconS' src={require('../images/icons/edit.png')} alt='EditBtn' onClick={() => setImageSelector(true)}/>
            </Col>
            <Col className='col-12 text-center'>
                <h6 className='textBold'>{userName}</h6>
                <span>Kundenummer: {clientNr}</span>
            </Col>
        </Row>

        <Row className='my-2 p-2'>
            <h5 className='textBold'>Name:</h5>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>Vorname:</p>
                <p>{userVorname}</p>
            </Col>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>Nachname:</p>
                <p>{userNachname}</p>
            </Col>
        </Row>

        <Row className='my-2 p-2'>
            <h5 className='textBold'>Kontakt:</h5>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>Telefon:</p>
                <p>{phone}</p>
            </Col>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>E-mail:</p>
                <p>{email}</p>
            </Col>
        </Row>

        <Row className='my-2 p-2'>
            <h5 className='textBold'>Adresse:</h5>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>Land:</p>
                <p>{country}</p>
            </Col>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>Ort:</p>
                <p>{city}</p>
            </Col>
            <Col className='col-12 d-flex justify-content-between border-bottom'>
                <p>Straße und Nummer:</p>
                <p>{street}</p>
            </Col>
        </Row>
        
        <Row>
            <Col>
                <p className='text-center textS m-0 opacity-75'>Um Ihre Daten zu schützen, erlauben wir niemandem außer uns, Ihre persönlichen Daten zu ändern.</p>
            </Col>
        </Row>
       
    </Container>
  )
}

export default UserCard