import React, {useRef, useState, } from 'react';

import Menu from '../components/Menu';
import Footer from '../components/Footer';
import MyCarusel from '../components/MyCarusel';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import styles from '../styles/home.module.css';

const Home = () => {
  const visionRef = useRef();
  const servicesRef = useRef();
  const missionRef = useRef();
  const aboutRef = useRef();
  const contactRef = useRef();

  const [vorname, setVorname] = useState('');
  const [nachname, setNachname] = useState('');
  const [email, setEmail] = useState('');
  const [message ,setMessage] = useState('');

  const singleItemsCircle = [
    require('../images/old chair.png'),
    require('../images/old_book.png'),
    require('../images/old_camera.png'),
    require('../images/old_hat.png'),
    require('../images/old_table.png'),
    require('../images/coins_pile.png'),
  ]

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if(validateEmail(email)){
      if(vorname.length > 0 && nachname.length > 0 && message.length > 0) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('vorname', vorname);
        formData.append('nachname', nachname);
        formData.append('message', message);
        formData.append('subject', 'Kontakt Form.')

        fetch ('http://zentralesammlerstelle.de/api/contact_email.php', {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then (data => {
          if(data.success) {
            alert('Email wurde erfolgreich Versendet');

            //empty the fields
            sendEmail(null);
            setMessage(null);
            setNachname(null);
            setVorname(null);
          } else {
            alert(data.message);
          }
        })

      } else {
        alert('Bitte füllen Sie alle Felder aus');
      }
    } else {
      alert('Bitte geben Sie eine gültige E-Mail-Adresse ein, um mit uns Kontakt aufzunehmen.')
    }
  }

  const validateEmail = (email) => {
    // Use a regular expression to validate the email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div>
      <Menu 
        onVisionClick={() => scrollToRef(visionRef)}
        onServicesClick={() => scrollToRef(servicesRef)}
        onMissionClick={() => scrollToRef(missionRef)}
        onAboutClick={() => scrollToRef(aboutRef)}
        onContactClick={() => scrollToRef(contactRef)}
      />

      <Container fluid className={styles.hero} id='heroSection'>
        <MyCarusel style={{ height: '100%'}} />
        <div style={{ zIndex: 10 }} className='d-flex align-items-center justify-content-center flex-column position-absolute top-50 start-50 translate-middle'>
          <h1 className={styles.title}>Alle Wege führen zu uns!</h1>
          <button onClick={() => scrollToRef(contactRef)} className={styles.contactBtn}>KONTAKT</button>
        </div>
      </Container>

      <Container className='p-2 p-md-5'>
        <Row>
          <Col className='col-12 col-md-auto'>
            <img src={require('../images/albert-einstein.jpg')} alt='Qutes' className={styles.EinsteinImg}/>
          </Col>
          <Col className='p-3'>
            <p>
              "Ich habe immer geglaubt, dass Antiquitäten nicht nur Gegenstände aus der Vergangenheit sind, 
              sondern lebendige Erinnerungen an eine andere Zeit. Jedes Stück erzählt eine Geschichte, 
              jedes Detail ist eine Note in der Symphonie der Geschichte. Antiquitäten sind nicht einfach 
              Dinge, sie sind Fenster zu einer Welt, die wir nie vergessen sollten."
            </p>
            <span className='opacity-50'>-Albert Einstein-</span>
          </Col>
        </Row>
      </Container>

      <Container ref={visionRef} fluid className={styles.visionCont}>
        <Row className='h-100 p-2 p-md-0'>
          <Col className='p-0 col-12 col-lg-6'>
            <img className={styles.oldClockImg} src={require('../images/clock.webp')} alt='Old Watch'/>
          </Col>
          <Col className='col-12 col-lg-6 d-flex gap-3 flex-column justify-content-center'>
            <div>
              <h4>Die Vision hinter <span className='accentLetter'>Z</span>ENTRALE-<span className='accentLetter'>S</span>AMMLERSTELLE.de</h4>
              <h6 className='text-bold'>Einzigartige Schätze bewahren und neu beleben mit der Zentralen Sammlerstelle</h6>
            </div>
            
            <p className='opacity-75'>
              Unsere Leidenschaft für das kulturelle Erbe treibt uns an, einzigartige Schätze zu bewahren
              und ihnen neues Leben einzuhauchen. Als Experten im An- und Verkauf von Objekten des
              kulturellen Erbes sind wir stolz darauf, Ihnen eine erlesene Auswahl historischer Möbel,
              archäologischer Fundstücke und ethnographischer Sammlerstücke präsentieren zu dürfen.
            </p>

            <button onClick={() => scrollToRef(aboutRef)} className='button'>MEHR</button>
          </Col> 
        </Row>
      </Container>

      <Container>
        <Row className='pb-4 pt-4'>
          <Col className='col-12 col-md-6 col-lg-3 text-center'>
            <img src={require('../images/icons/customer-service.png')} alt='Dienstleistung' className={styles.serviceIcon}/>
            <p className={styles.titleService}>Verkaufen beginnt hier.</p>
            <p className={styles.descriptionService}>Die Person, die ein Objekt verkaufen möchte, setzt sich mit meinem Kunden in Verbindung und vereinbart einen Termin.</p>
          </Col>
          <Col className='col-12 col-md-6 col-lg-3 text-center'>
            <img src={require('../images/icons/conversation.png')} alt='Dienstleistung' className={styles.serviceIcon}/>
            <p className={styles.titleService}>Objekt ins Rampenlicht rücken.</p>
            <p className={styles.descriptionService}>Ein persönliches Treffen zu besprechen und Login-Daten für die Website einzurichten, wo das Objekt, der Preis und mehr präsentiert werden.</p>
          </Col>
          <Col className='col-12 col-md-6 col-lg-3 text-center'>
            <img src={require('../images/icons/recruitment.png')} alt='Dienstleistung' className={styles.serviceIcon}/>
            <p className={styles.titleService}>Die Suche beginnt.</p>
            <p className={styles.descriptionService}>Gemeinsam wird nach interessierten Käufern gesucht, um das Objekt bestmöglich zu platzieren.</p>
          </Col>
          <Col className='col-12 col-md-6 col-lg-3 text-center'>
            <img src={require('../images/icons/deal.png')} alt='Dienstleistung' className={styles.serviceIcon}/>
            <p className={styles.titleService}>Einigung erzielen.</p>
            <p className={styles.descriptionService}>Wenn der Preis fair ist, wird der Deal abgeschlossen – ein zufriedenstellender Abschluss für alle Beteiligten.</p>
          </Col>
        </Row>
      </Container>

      <Container ref={servicesRef} fluid className={styles.servicesCont}>
        <Container>
          <Row>
            <Col className='col-12 col-lg-6 mb-5 mb-lg-0'>
              <h4 className='text-bold'>Dinsteistungen</h4>
              
              <div className='d-flex flex-column gap-2'>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Objektbewertung und Expertise</span>
                </div>
                <div className='d-flex gap-1 align-items-centr'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>An- und Verkaufsberatung</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Restaurationsdienstleistungen</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Sammlungsmanagement</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Auktionssupport</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Recherche und Herkunftsverfolgung</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Veranstaltung von Ausstellungen</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Versicherungsbewertungen</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Kunden- und Sammlerworkshops</span>
                </div>
                <div className='d-flex gap-1 align-items-center'>
                  <img className='iconM' src={require('../images/icons/check-mark.png')} alt='Ckeck Mark'/>
                  <span>Digitales Archivmanagement</span>
                </div>
              </div>
            </Col>
            <Col className='col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end'>
              <div className='position-relative' style={{height: 300, width: 300}}>
                <div className={styles.decoImageBorderTop}></div>

                <img src={require('../images/archeologist.webp')} alt='DecoImg' className={styles.decorationImage}/>

                <div className={styles.decoImageBorderBottom}></div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container ref={missionRef} fluid className={styles.misionCont}>
        <Row className='p-5'>
          <Col className='col-12 col-md-6'>
            <div className={styles.misionTextCont}>
              <p className='text-center'>Unsere Mission ist es, die Geschichte durch die Hände von Sammlern, Enthusiasten und Liebhabern zu transportieren.</p>
            </div>
          </Col>
        </Row>

        <Row className='p-5'>
          <Col className='d-flex justify-content-center'>
            <div className={styles.missionTitleCont}>
              <h6 className='text-white text-bold'>MISSION</h6>
            </div>
          </Col>
        </Row>

        <Row className='p-5 justify-content-end'>
          <Col className='col-12 col-md-6'>
            <div className={styles.misionTextCont}>
              <p className='text-center'>Jedes einzelne Stück in unserem Sortiment erzählt eine faszinierende Geschichte, und wir sind hier, um diese Geschichten mit Ihnen zu teilen.</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container ref={aboutRef} fluid style={{backgroundColor: '#F5F5F5'}}>
        <Container>
          <Row className='p-5'>
            {singleItemsCircle.map((item, index) => {
              return (
                <Col key={index} className='d-flex justify-content-center align-items-center flex-column gap-3'>
                  <img src={item} alt='Antique item' className={styles.circleItemImg}/>
                  <div className={styles.circleItemLine}></div>
                </Col>
              )
            })}
          </Row>
          <Row className='justify-content-center'>
            <Col className='col-12 col-md-8'>
              <p className='text-center'>Mit einem besonderen Fokus auf Gegenstände von kulturellem und historischem Wert
                bieten wir Ihnen nicht nur einzigartige Einblicke in vergangene Epochen, sondern auch die
                Möglichkeit, Teil dieser faszinierenden Reise zu werden. Unsere Sammlung umfasst sorgfältig
                ausgewählte Objekte, die nicht nur ästhetisch ansprechend sind, sondern auch einen
                unvergleichlichen kulturellen Wert repräsentieren.
              </p>
            </Col>
          </Row>

          <Row className='p-3'> 
            <Col className='d-flex justify-content-center'>
              <img width={300} src={require('../images/icons/decoLine.png')} alt='DecorationLine'/>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className=''>
        <Row>
          <Col className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-4 py-5 py-lg-0'>
            <h5 className='text-bold text-center'>Ihr Experte für die Auswahl, den Kauf und die Pflege kultureller Erbstücke</h5>

            <img src={require('../images/icons/3LinesDeco.png')} alt='Cusotm Deco Lines'/>

            <p className='text-center opacity-75 px-0 px-lg-5'>
              Bei der Zentralen Sammlerstelle stehen Professionalität und Kundenzufriedenheit an erster
              Stelle. Unser engagiertes Team von Experten steht Ihnen gerne zur Verfügung, um Sie bei der
              Auswahl, dem Kauf oder der Pflege dieser einzigartigen Erbstücke zu unterstützen. Wir laden
              Sie herzlich ein, Teil unserer Gemeinscha9 zu werden, die die Schönheit und Bedeutung
              kultureller Erbeschätze schätzt.
            </p>
          
          </Col>
          <Col className='p-0 col-12 col-lg-6'>
            <img className={styles.oldClockImg} src={require('../images/books_1.webp')} alt='Antique Books'/>
          </Col>
        </Row>

        <Row>
          <Col className='p-0 col-12 col-lg-6 order-2 order-lg-1'>
            <img className={styles.oldClockImg} src={require('../images/history.webp')} alt='Antique Books'/>
          </Col>

          <Col className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-4 order-1 order-lg-2 py-5 py-lg-0'>
            <h5 className='text-bold text-center'>Zeitreise der Eleganz: Entdecken Sie vergangene Schätze mit der <span className='accentLetter'>Zentralen Sammlerstelle</span></h5>

            <img src={require('../images/icons/3LinesDeco.png')} alt='Cusotm Deco Lines'/>

            <p className='text-center opacity-75 px-0 px-lg-5'>
              Entdecken Sie mit uns die verborgenen Schätze vergangener Zeiten und lassen Sie sich von
              der zeitlosen Eleganz und Geschichte unserer Sammlung inspirieren. Zentrale Sammlerstelle
              – wo Kulturerbe lebendig wird.
            </p>
          </Col>
        </Row>
      </Container>

      <Container ref={contactRef} fluid className={styles.emailDecoImage}>
        <Container className={`px-0 px-lg-5 ${styles.emailFormCont}`}>
            <h5 className='text-center p-3'>Schreib uns eine Email!</h5>
            <Form onSubmit={sendEmail}>
              <Row className='mb-3'>
                <Col>
                  <Form.Control type='text' value={vorname} placeholder="Vorname" onChange={(e) => setVorname(e.target.value)}/>
                </Col>
                <Col>
                  <Form.Control type='text' value={nachname} placeholder="Nachname" onChange={(e) => setNachname(e.target.value)}/>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Control type='email' value={email} placeholder="E-mail Adresse" onChange={(e) => setEmail(e.target.value)}/>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Control type='text' value={message} as="textarea" rows={3} placeholder='Deine Nachricht...' onChange={(e) => setMessage(e.target.value)}/>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <button type='submit' className={styles.sendEmailBtn}>SENDEN</button>
                </Col>
              </Row>
            </Form>
        </Container>
      </Container>

      <Footer />
      
    </div>
  )
}

export default Home