import React from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AGB = () => {
  return (
    <div>
        <Menu />

        <Container className='p-5'>
            <Row>
                <Col>
                    <h3 className='AGBtext'>A&B Beratungs- und Verwaltung GmbH</h3>
                    <h4 className='AGBtext'>Allgemeine Geschäftsbedingungen</h4>

                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 1 Geltungsbereich</h5>
                        <p className='AGBtext'>
                            Die vorliegenden Geschäftsbedingungen (im Folgenden „AGB“) in der zum Zeitpunkt der  Bestellung vorliegenden Fassung finden Anwendung 
                            auf die zwischen dem Kunden und der  A&B Beratungs- und Verwaltung GmbH, Mendelssohnstraße 27, 10405 Berlin. Die AGB sind  Grundlage 
                            aller vertraglichen Beziehungen der A&B Beratungs- und Verwaltung GmbH zu  Kunden/Dritten.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 2 Bestellung/Vertragsabschluss</h5>
                        <p className='AGBtext'>
                            2.1 Es kommt zwischen dem Kunden und der A&B Beratungs- und Verwaltung GmbH durch  Ausfüllen und Übergabe des Bestellformulars an 
                            den von A&B Beratungs- und Verwaltung  GmbH Bevollmächtigten Handelsvertreter („Angebot”) und Gegenzeichnung durch den von  A&B Beratungs- 
                            und Verwaltung GmbH bevollmächtigten Handelsvertreter („Annahme”) ein  Vertrag zustande. Hiervon kann durch ausdrückliche Vereinbarung abgewichen 
                            werden. 2.2 Der Kunde verpflichtet sich, im Bestellformular anzugebenden Daten vollumfänglich und  richtig anzugeben. Der Kunde ist verpflichtet, 
                            der A&B Beratungs- und Verwaltung GmbH  Änderungen dieser Daten, insbesondere Adressänderung unverzüglich bekannt zu geben.  Sämtliche Kosten, 
                            die der A&B Beratungs- und Verwaltung GmbH durch nicht rechtzeitige  Bekanntgabe einer Änderung dieser Daten entstehen, hat der Kunde vollumfänglich 
                            zu tragen. 2.3 Soweit nicht schriftlich gesondert formuliert wird keine Beschaffenheit des gekauften  Produkts vereinbart oder garantiert. Zeichnungen, 
                            Abbildungen, Maße, Gewichte oder sonstige  Leistungsdaten und Beschreibungen in Kataloge oder sonstigen Präsentationsunterlagen dienen  grundsätzlich 
                            nur der Veranschaulichung, soweit nicht im Einzelfall etwas anderes schriftlich  vereinbart ist. Sofern nicht bereits bei Vertragsabschluss vorhersehbar, 
                            ist die A&B Beratungs und Verwaltung GmbH bei Vorliegen eines wichtigen Grundes und unter Berücksichtigung der  berechtigten Interessen des Kunden berechtigt, 
                            die angebotenen Produkte oder Dienstleistungen  technisch verbessert oder in einem leicht veränderten Design zu liefern. Die Änderung der  Leistung darf 
                            nicht zu einer nachträglichen Änderung der wirtschaftlichen Bedingungen zu  Gunsten der A&B Beratungs- und Verwaltung GmbH führen und/oder zu Lasten des Kunden  
                            führen.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 3. Lieferung</h5>
                        <p className='AGBtext'>3.1 Soweit die A&B Beratungs- und Verwaltung GmbH Lieferzeiten angibt, berechnen sich diese  vom Zeitpunkt des Vertragsschlusses.</p>
                        <p className='AGBtext'>
                            3.2 Die A&B Beratungs- und Verwaltung GmbH wird von ihrer Lieferschuld/ vertraglichen  Verpflichtung frei, sobald sie die Ware an 
                            die vom Kunden letztgenannte Adresse versandt hat. 3.3 Teillieferungen sind zulässig, sofern dies für den Kunden nicht unzumutbar ist. Eine  
                            Teillieferung ist insbesondere dann nicht unzumutbar, wenn die Teillieferung für den Kunden  bestimmungsgemäß verwendbar, die Lieferung der restlichen 
                            bestellten Ware sichergestellt und  dem Kunden durch die Teillieferung kein erheblicher Mehraufwand oder zusätzliche Kosten  entstehen.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 4 Widerrufsrecht</h5>
                        <p className='AGBtext'>
                            4.1 Sind die Kunden der A&B Beratungs- und Verwaltung GmbH Verbraucher, so steht Ihnen  nach Maßgabe der der nachfolgend aufgeführten 
                            Belehrung ein Widerrufsrecht zu. Verbraucher  ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder  
                            ihrer gewerblichen noch ihrer Selbständigen beruflichen Tätigkeit zugerechnet werden können. 4.2 Widerrufsbelehrung / Widerrufsrecht
                        </p>
                        <p className='AGBtext'>
                            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu  widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen 
                            benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw.  hat. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns A&B Beratungs- und Verwaltung  
                            GmbH, vertr. durch den Geschäftsführer Adrian Sari, Mendelssohnstraße 27, 10405 Berlin,  Deutschland, E-Mail-Adresse: info@zentralesammlerstelle.de mittels einer eindeutigen  
                            Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen  Vertrag zu widerrufen, informieren.
                        </p>
                        <p className='AGBtext'>
                            Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, dass jedoch nicht  vorgeschrieben ist.
                        </p>
                        <p className='AGBtext'>
                            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des  Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                        </p>
                        <h5 className='AGBtext'>Folgen des Widerrufs</h5>
                        <ul>
                            <li className='AGBtext'>
                                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten  haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, 
                                die sich daraus  ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste  Standardlieferung gewählt haben), unverzüglich und spätestens binnen 
                                vierzehn Tagen ab dem  Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns  eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe 
                                Zahlungsmittel, das Sie bei der  ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas  anderes vereinbart; in keinem Fall werden Ihnen 
                                wegen dieser Rückzahlung Entgelte berechnet.
                            </li>
                            <li className='AGBtext'>
                                Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis  Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, 
                                je nachdem, welches  der frühere Zeitpunkt ist.
                            </li>
                            <li className='AGBtext'>
                                Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem  Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden  
                                oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn  Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
                            </li>
                            <li className='AGBtext'>
                                Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust  auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht  
                                notwendigen Umgang mit Ihnen zurückzuführen ist.
                            </li>
                        </ul>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 5 Eigentumsvorbehalt</h5>
                        <p className='AGBtext'>5.1 Das gelieferte Produkt bleibt bis zur vollständigen Zahlung des Kaufpreises im Eigentum der  A&B Beratungs- und Verwaltung GmbH.</p>
                        <p className='AGBtext'>
                            5.2 Vor vollständiger Begleichung der Rechnung ist es dem Kunden untersagt, das Produkt zu  verpfänden, sicherungsweise zu übereignen oder Dritten 
                            sonstige Rechte daran einzuräumen.  Zwangsvollstreckungsmaßnahmen und andere die Rechtsstellung der A&B Beratungs- und  Verwaltung GmbH beeinträchtigende 
                            Zugriffe Dritter auf die das noch unter  Eigentumsvorbehalt stehende Produkt hat der Kunde der A&B Beratungs- und Verwaltung  GmbH gegenüber unverzüglich 
                            schriftlich anzuzeigen. Der Kunde hat derartigen Maßnahmen  unter Hinweis auf den bestehenden Eigentumsvorbehalt bzw. das Eigentum der A&B Beratungs und 
                            Verwaltung GmbH an dem Produkt umgehend zu widersprechen.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 6 Zahlungsmodalitäten</h5>
                        <p className='AGBtext'>6.1 Die Zahlung des Kunden wird mit Eingang der Rechnung sofort fällig. Die Parteien können  im Einzelfall etwas Abweichendes vereinbaren.</p>
                        <p className='AGBtext'>
                            6.2 Befindet sich der Kunde gegenüber der A&B Beratungs- und Verwaltung GmbH mit einer  Zahlungsverpflichtung im Verzug, so werden sämtliche bestehende Forderungen sofort fällig.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 7 Gewährleistung</h5>
                        <p className='AGBtext'>
                            7.1 Soweit die von der A&B Beratungs- und Verwaltung GmbH gelieferten Produkte mangelhaft  sind, sind die Kunden im Rahmen der gesetzlichen Bestimmungen berechtigt, Nacherfüllung zu  
                            verlangen, von dem Vertrag zurückzutreten oder den Kaufpreis zu mindern. Ansprüche auf  Schadensersatz sind nach Maßgabe des § 8 beschränkt.
                        </p>
                        <p className='AGBtext'>
                            7.2 Rechte wegen Mängeln stehen dem Kunden darüber hinaus auch im Rahmen einer  Beschaffenheits- und/oder Haltbarkeitsgarantie zu, sofern die A&B Beratungs- und Verwaltung  
                            GmbH eine solche bezüglich des verkauften Produkts im Einzelfall ausdrücklich abgegeben  haben.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 8. Haftungsbeschränkung</h5>
                        <p className='AGBtext'>
                            8.1 Die A&B Beratungs- und Verwaltung GmbH haftet für Vorsatz und grobe Fahrlässigkeit.  Ferner haften sie für die fahrlässige Verletzung von Pflichten, deren Erfüllung die  
                            ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht, deren Verletzung die  Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung der Kunde regelmäßig  
                            vertrauen dürfen. Im letztgenannten Fall haftet die A&B Beratungs- und Verwaltung GmbH  jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Gleiches gilt für  
                            Pflichtverletzungen ihrer Mitarbeiter und Erfüllungsgehilfen. Darüber hinaus wird eine Haftung  auf Schadensersatz ausgeschlossen. 
                        </p>
                        <p className='AGBtext'>
                            8.2 Die vorstehenden Haftungsausschlüsse gelten nicht für Schadensersatzansprüche von  Kunden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder aus vorsätzlichen
                            oder grob fahrlässigen Pflichtverletzungen oder bei arglistigem Verhalten seitens der Angestellten  oder Erfüllungsgehilfen der A&B Beratungs- und Verwaltung GmbH, bei Haftung 
                            für garantierte  Beschaffenheitsmerkmale oder nach dem Produkthaftungsgesetz
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 9 Hinweis gemäß § 36 Verbraucherstreitbeteiligungsgesetz (VSBG)</h5>
                        <p className='AGBtext'>
                            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die  unter <a href='https://ec.europa.eu/consumers/odr/'>https://ec.europa.eu/consumers/odr/</a> zu finden ist. Zur Teilnahme an einem  
                            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle ist die A&B Beratungs- und  Verwaltung GmbH nicht verpflichtet und auch nicht bereit. 
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 10 Vertragssprache / Rechtswahl / Gerichtsstand</h5>
                        <p className='AGBtext'>
                            10.1 Die Vertragssprache ist Deutsch.
                        </p>
                        <p className='AGBtext'>
                            10.2 Auf Verträge zwischen dem Kunden und der A&B Beratungs- und Verwaltung GmbH  findet das Recht der Bundesrepublik Deutschland unter Ausschluss seiner kollisionsrechtlichen  
                            Bestimmungen und unter Ausschluss des UN-Kaufrechts Anwendung. Diese Rechtswahl gilt nur  insoweit, als nicht zwingende anwendbare Verbraucherschutzvorschriften des Staates, 
                            in dem der  Verbraucher zum Zeitpunkt seiner Bestellung seinen gewöhnlichen Aufenthalt hat,  entgegenstehen.
                        </p>
                        <p className='AGBtext'>
                            10.3 Sofern es sich bei dem Kunden als Besteller um einen Kaufmann, eine juristische Person des  öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, 
                            ist  Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen der Sitz der A&B Beratungs- und  Verwaltung GmbH (10405 Berlin). Soweit der Kunde bei Abschluss des Vertrags 
                            seinen  Wohnsitz oder gewöhnlichen Aufenthalt in Deutschland hatte und entweder zum Zeitpunkt der  Klageerhebung durch die A&B Beratungs- und Verwaltung GmbH aus Deutschland 
                            verlegt hat  oder der Wohnsitz des Kunden oder sein gewöhnlicher Aufenthaltsort zu diesem Zeitpunkt unbekannt ist, ist Gerichtsstand für alle Streitigkeiten der Sitz der A&B 
                            Beratungs- und  Verwaltung GmbH (10405 Berlin). 
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>§ 11 Salvatorische Klausel</h5>
                        <p className='AGBtext'>
                            Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam oder nichtig sein oder  werden oder sollten diese AGB eine Lücke enthalten, so bleibt die Wirksamkeit 
                            der  Bestimmungen dieser AGB im Übrigen hiervon unberührt. Anstelle der unwirksamen Klauseln  treten, soweit vorhanden, die gesetzlichen Vorschriften.
                        </p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h5 className='AGBtext'>Steuer ID:</h5>
                    <p className='AGBtext'>i.G</p>
                </Col>
                <Col>
                    <h5 className='AGBtext'>ID Nummer:</h5>
                    <p className='AGBtext'>i.G</p>
                </Col>
            </Row>
        </Container>

        <Footer />
      
    </div>
  )
}

export default AGB
