import React, {useState, useEffect} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from '../styles/home.module.css'


const MyCarusel = () => {

  return (
    <div className={styles.carouselContainer}>
        <Carousel autoPlay infiniteLoop showThumbs={false}>
            <div>
                <img 
                    className={styles.sliderImg} 
                    src={require("../images/old_room.webp")} 
                    alt="Slide 1" 
                    style={{ maxHeight: '100%', objectFit: 'cover'}}
                />
            </div>
            <div>
                <img 
                    className={styles.sliderImg} 
                    src={require("../images/coin.webp")} 
                    alt="Slide 2" 
                    style={{ maxHeight: '100%', objectFit: 'cover' }}
                />
            </div>
            <div>
                <img 
                    className={styles.sliderImg} 
                    src={require("../images/modern_old.webp")} 
                    alt="Slide 3" 
                    style={{ maxHeight: '100%', objectFit: 'cover' }}
                />
            </div>
            <div>
                <img 
                    className={styles.sliderImg} 
                    src={require("../images/globe.webp")} 
                    alt="Slide 4" 
                    style={{ maxHeight: '100%', objectFit: 'cover' }}
                />
            </div>
            {/* Add more slides as needed */}
        </Carousel>
    </div>
  )
}

export default MyCarusel