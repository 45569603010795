import React from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Datenschutz = () => {
  return (
    <div>
        <Menu />

        <Container className='p-5'>
            <Row>
                <Col>
                    <h3 className='AGBtext'>A&B Beratungs- und Verwaltung GmbH</h3>
                    <h4 className='AGBtext'>Datenschutz</h4>

                    <div className='my-5'>
                        <h5 className='AGBtext'>Datenschutzerklärung (Privacy Policy)</h5>
                        <p className='AGBtext'>
                            Vielen Dank für Ihr Interesse an unserer Website. Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Nachfolgend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Verantwortliche Stelle</h5>
                        <p className='AGBtext'>
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist A&B Beratungs- und Verwaltung GmbH, Mendelssohnstraße 27, 10405 Berlin, <a href='info@zentralesammlerstelle.de'>info@zentralesammlerstelle.de</a>.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Datenerfassung und -verwendung</h5>
                        <p className='AGBtext'>
                            Wir erheben und nutzen personenbezogene Daten unserer Nutzer nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung erfolgt nur mit Ihrer Einwilligung oder auf Grundlage gesetzlicher Vorschriften.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Kontaktformular</h5>
                        <p className='AGBtext'>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Datenübertragung</h5>
                        <p className='AGBtext'>
                            Die Übertragung Ihrer Daten erfolgt verschlüsselt über HTTPS, um die Sicherheit Ihrer persönlichen Informationen während der Übermittlung zu gewährleisten.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Passwortsicherheit</h5>
                        <p className='AGBtext'>
                            Wir speichern Passwörter in verschlüsselter Form und setzen bewährte Sicherheitsstandards ein, um die Integrität und Vertraulichkeit Ihrer Passwörter zu schützen.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Datenspeicherung</h5>
                        <p className='AGBtext'>
                            Ihre persönlichen Daten werden sicher in unserer MySQL-Datenbank gespeichert und sind nur autorisierten Personen zugänglich. Wir bewahren Ihre Daten nur so lange auf, wie es für den Zweck der Datenverarbeitung notwendig ist.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Verwendungszweck</h5>
                        <p className='AGBtext'>
                            Wir verwenden Ihre Daten ausschließlich zur Verwaltung Ihrer Benutzerkonten und zur Anzeige der von Ihnen zum Verkauf angebotenen Artikel. Ihre Daten werden nicht für Werbezwecke an Dritte weitergegeben.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Zugriffsrechte</h5>
                        <p className='AGBtext'>
                            Sie haben das Recht, auf Ihre gespeicherten personenbezogenen Daten zuzugreifen, jedoch sind bestimmte Aktionen wie Bearbeiten, Löschen oder Hinzufügen von Informationen auf autorisiertes Personal beschränkt. Dies dient dazu, einen höheren Schutz Ihrer Daten zu gewährleisten. Kontaktieren Sie uns bitte unter <a href='info@zentralesammlerstelle.de'>info@zentralesammlerstelle.de</a>, um auf Ihre Daten zuzugreifen oder Änderungen vorzunehmen.
                        </p>
                    </div>
                    <div className='my-5'>
                        <h5 className='AGBtext'>Verwendung von Cookies</h5>
                        <p className='AGBtext'>
                            Unsere Webseite verwendet keine Cookies. Wir erfassen keinerlei persönliche Informationen über Ihre Online-Aktivitäten.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>

        <Footer />
      
    </div>
  )
}

export default Datenschutz
