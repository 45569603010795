import React from 'react';
import styles from '../styles/shopFormular.module.css';

const ShopContactForm = ({ selectedItem }) => {
  return (
    <div className={styles.background}>
        <div className ={styles.formCont}>
            <form>
                <h1>Thnak you for you interesse</h1>
            </form>
        </div>
    </div>
  )
}

export default ShopContactForm
