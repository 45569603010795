import React, {useState} from 'react';
import styles from '../styles/imageSelector.module.css';

const ImageSelector = ({setImageSelector}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const token = localStorage.getItem('sammlerToken') || sessionStorage.getItem('sammlerToken');

    const handleProfileChange = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('profileImage', selectedImage);
        
        try {
            const response = await fetch("https://zentralesammlerstelle.de/api/update_user_image.php", {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });
            if(response.ok) {
                const data = await response.json();
                if(data.success) {
                    window.location.reload(false);
                } else {
                    console.log(data.message)
                    console.log(data.error)
                }
            } else {
                console.log('Error')
            }
        }catch (error) {
            console.log(error)
        }
    }

  return (
    <div className={styles.bkg}>
        <div className={styles.body}>
            <p className='text-center textBold'>Lade ein Bild hoch</p>
            <form>
                <input className='mb-3' type="file"  accept="image/*"  onChange={(e) => setSelectedImage(e.target.files[0])} />

                <div className='d-flex justify-content-between gap-2'>
                    <button className={styles.cancelBtn} onClick={() => setImageSelector(false)}>Cancel</button>
                    <button  className={styles.sendBtn} type='' onClick={(e) => handleProfileChange(e)}>SENDEN</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ImageSelector