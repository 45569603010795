import React, {useState, useEffect} from 'react';
import Footer from '../components/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from '../styles/showroom.module.css';
import config from '../config';
import RenderShowroom from '../components/RenderShowroom';

const Showroom = () => {
    const [searchWord, setSearchWord] = useState("");
    const [items, setItems] = useState([]);
    const [errorText, setErrorText] = useState('');
    const [limit, setLimit] = useState(9);
    const [category, setCategory] = useState('Alle');
    const [categoryList, setCategoryList] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        fetchItems();
    }, [limit, category])

    useEffect(() => {
        searchItem();
    }, [searchWord])

    const fetchItems = async () => {
        setErrorText('Loading...');

        try {
            const response = await fetch(`https://zentralesammlerstelle.de/api/get_all_items_clients.php?limit=${limit}&category=${category}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${config.REACT_APP_API_KEY}` 
                }
            })

            if(response.ok) {
                const data = await response.json();

                if(data.success) {
                    setItems(data.items);
                }else {
                    setErrorText(data.message);
                }
            }else {
                console.log('Bad response fetching items: ', response.statusText);
                setErrorText('Server error.');
            }
        }catch (error) {
            console.log('Error fetching the items', error.message);
            setErrorText('Server error');
        }finally {
            setErrorText('Fertig!')
        }
    }

    const fetchCategories = async () => {
        try {
            const response = await fetch(`https://zentralesammlerstelle.de/api/get_categories.php`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${config.REACT_APP_API_KEY}` 
                }
            })

            if(response.ok) {
                const data = await response.json();

                if(data.success) {
                    setCategoryList(data.categories);
                }else {
                    setErrorText(data.message);
                }
            }else {
                console.log('Bad response fetching Category List: ', response.statusText);
                setErrorText('Server error.');
            }
        }catch (error) {
            console.log('Error fetching the Category List', error.message);
            setErrorText('Server error');
        }
    }

    const searchItem = async () => {
        setErrorText('Searching...');
    
        // Ensure searchWord is not empty and trim it to avoid unnecessary API calls with just spaces
        const trimmedSearchWord = searchWord.trim();
        if (!trimmedSearchWord) {
            setErrorText('Please enter a search term.');
            return;
        }
    
        try {
            const response = await fetch(`https://zentralesammlerstelle.de/api/search_items.php?search=${encodeURIComponent(trimmedSearchWord)}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${config.REACT_APP_API_KEY}`
                }
            });
    
            if (response.ok) {
                const data = await response.json();
    
                if (data.success) {
                    setItems(data.items);
                } else {
                    setErrorText(data.message);
                }
            } else {
                setErrorText('Server error: ' + response.statusText);
            }
        } catch (error) {
            console.error('Error searching items:', error);
            setErrorText('Network error: ' + error.message);
        }
    };
    

  return (
    <div>
        <Container fluid className='bg-dark'>
            <Container>
                <Row className='p-3'>
                    <Col style={{cursor: 'pointer'}} onClick={() => window.location.replace('/')} className='d-flex flex-row gap-2 align-items-center'>
                        <img src={require('../images/icons/back-w.png')} className='iconXS' alt='Back'/>
                        <span className='text-white'>Zurück</span>
                    </Col>
                    <Col>
                        <h5 className='text-center text-white'>ZENTRALE ZAMMLERSTELLE</h5>
                        <h6 className='text-center text-white'>Showroom</h6>
                    </Col>
                    <Col>

                    </Col>
                </Row>
            </Container>
        </Container>

        <Container className='p-3'>
            <Row>
                <Col>
                    <h5 className=''>Wir schreiben</h5>
                    <h1 className=''>Geschichte</h1>
                </Col>
                <Col className='d-flex flex-row justify-content-between'>
                    {categoryList &&
                        categoryList.map((item) => {
                            return(
                                <span 
                                    onClick={() => setCategory(item.name)} 
                                    key={item.id} 
                                    className={styles.category}
                                >
                                    {item.name}
                                </span>
                            )
                        })
                    }
                </Col>

                <Col>
                    <div className={styles.searchInputCont}>
                        <input placeholder='Suchen' className={styles.searchInput} onChange={(e) => setSearchWord(e.target.value)}/>
                        <img src={require('../images/icons/search.png')} className='iconXS' alt='Search'/>
                    </div>
                </Col>
            </Row>
        </Container>

        <Container>
            {items.length > 0 ? 
                
                <RenderShowroom items={items} />
                   
            :
                <div className='text-center'>
                    <img src={require('../images/icons/loading.gif')} className='iconXXXL' alt='Loading Icon'/>
                    <p>{errorText}</p>
                </div>
            }
        </Container>

        <Container>
            <Row>
                <Col className='text-end'>
                    <button onClick={() => setLimit(limit + 9)} className='button'>MEHR</button>
                </Col>
            </Row>
        </Container>

        <Footer />
    </div>
  )
}

export default Showroom